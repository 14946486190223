import React, { useState } from 'react'

import Layout from '../components/layout'
import ModalVideo from '../components/modal-video'
import SEO from '../components/seo'

function IndexPage() {
  const [collapsed, setCollapsed] = useState(true)
  return (
    <Layout>
      <SEO
        keywords={[`aluminum profile`, `cnc`, `automation`, `window`]}
        title="Home"
      />
      <div>
        <div>
          <div className="relative overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-0">
              <svg
                className="absolute top-0 transform translate-x-64 -translate-y-8 left-1/2"
                width="640"
                height="784"
                fill="none"
                viewBox="0 0 640 784"
              >
                <defs>
                  <pattern
                    id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
                    x="118"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-orange-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  y="72"
                  width="640"
                  height="640"
                  className="text-gray-50"
                  fill="currentColor"
                />
                <rect
                  x="118"
                  width="404"
                  height="784"
                  fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
                />
              </svg>
            </div>
            <div className="relative pb-16 md:pb-20 lg:pb-24 xl:pb-32">
              <div className="max-w-screen-xl px-4 mx-auto mt-8 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                  <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                    <h2 className="mt-1 text-4xl font-extrabold leading-10 tracking-tight text-gray-700 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
                      Automate your profile fabrication
                      <br className="hidden md:inline" />
                      <span className="text-orange-600">
                        in just a few days
                      </span>
                    </h2>
                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Our software can automate your production floor{' '}
                      <strong>within days</strong>. Let us help you increase
                      your productivity by <strong>up to 300%</strong>. Uni_Link
                      is a powerful all-in-one software solution for all CNC
                      profile machines.
                    </p>
                    <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                      <a
                        href="/#contact"
                        className="w-full px-6 py-3 mt-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-gray-800 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:shadow-outline active:bg-gray-900 sm:mt-0 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                      >
                        Request more Information
                      </a>
                    </div>
                  </div>
                  <div className="relative mt-12 sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                    <svg
                      className="absolute top-0 origin-top transform scale-75 -translate-x-1/2 -translate-y-8 left-1/2 sm:scale-100 lg:hidden"
                      width="640"
                      height="784"
                      fill="none"
                      viewBox="0 0 640 784"
                    >
                      <defs>
                        <pattern
                          id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                          x="118"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="4"
                            height="4"
                            className="text-gray-200"
                            fill="currentColor"
                          />
                        </pattern>
                      </defs>
                      <rect
                        y="72"
                        width="640"
                        height="640"
                        className="text-gray-50"
                        fill="currentColor"
                      />
                      <rect
                        x="118"
                        width="404"
                        height="784"
                        fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
                      />
                    </svg>
                    <div className="relative w-full mx-auto rounded-lg shadow-xl lg:max-w-md">
                      <button
                        onClick={() => setCollapsed(false)}
                        className="relative block w-full overflow-hidden rounded-lg focus:outline-none focus:shadow-outline"
                      >
                        <img
                          className="w-full"
                          src="https://res.cloudinary.com/dhcx3vzmg/image/upload/v1599126747/rpwvzlr6rvdxscd95k9p.jpg"
                          alt="Uni_Link Software"
                        />
                        <div className="absolute inset-0 flex items-center justify-center w-full h-full">
                          <svg
                            className="w-20 h-20 text-orange-500"
                            fill="currentColor"
                            viewBox="0 0 84 84"
                          >
                            <circle
                              opacity="0.9"
                              cx="42"
                              cy="42"
                              r="42"
                              fill="white"
                            />
                            <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="stats">
          <div className="bg-green-600">
            <div className="max-w-screen-xl px-4 py-12 mx-auto sm:py-16 sm:px-6 lg:px-8 lg:py-20">
              <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-3xl font-extrabold leading-9 text-white sm:text-4xl sm:leading-10">
                  Trusted by 700+ companies
                </h2>
                <p className="mt-3 text-xl leading-7 text-green-200 sm:mt-4">
                  From small to large companies with profile manufacturing, we
                  have over 700+ customers worldwide.
                </p>
              </div>
              <div className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
                <div>
                  <p className="text-5xl font-extrabold leading-none text-white">
                    300%
                  </p>
                  <p className="mt-2 text-lg font-medium leading-6 text-green-200">
                    more production
                  </p>
                </div>
                <div className="mt-10 sm:mt-0">
                  <p className="text-5xl font-extrabold leading-none text-white">
                    30%
                  </p>
                  <p className="mt-2 text-lg font-medium leading-6 text-green-200">
                    less material loss
                  </p>
                </div>
                <div className="mt-10 sm:mt-0">
                  <p className="text-5xl font-extrabold leading-none text-white">
                    50+
                  </p>
                  <p className="mt-2 text-lg font-medium leading-6 text-green-200">
                    Models of profile machine centers and saws supported.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="overview" className="overview">
          <div className="py-16 overflow-hidden bg-gray-50 lg:py-24">
            <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-screen-xl">
              <svg
                className="absolute hidden transform -translate-x-1/2 lg:block left-full -translate-y-1/4"
                width="404"
                height="784"
                fill="none"
                viewBox="0 0 404 784"
              >
                <defs>
                  <pattern
                    id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-green-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="784"
                  fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
                />
              </svg>

              <div className="relative">
                <h3 className="text-4xl font-extrabold leading-8 tracking-tight text-center text-orange-600 sm:text-5xl sm:leading-10">
                  Improve your machine efficiency
                </h3>
                <p className="max-w-3xl mx-auto mt-4 text-2xl leading-7 text-center text-gray-500">
                  Start running your production from your office... or your
                  home.
                </p>
              </div>

              <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="relative">
                  <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                    Ease and flexibility
                  </h4>

                  <ul className="mt-10">
                    <li>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                            <svg
                              className="w-6 h-6"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="text-lg font-medium leading-6 text-gray-900">
                            Turn your CNC machine in a printer
                          </h5>
                          <p className="mt-2 text-base leading-6 text-gray-500">
                            Uni_Link makes piloting your profile machines as
                            easy as printing documents from a PC. Send your
                            production lists from your office or home.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                            <svg
                              className="w-6 h-6"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="text-lg font-medium leading-6 text-gray-900">
                            Any machine – any software
                          </h5>
                          <p className="mt-2 text-base leading-6 text-gray-500">
                          Uni_Link is compatible with all machines for machining plastic, 
                          aluminum and light steel profiles and is also compatible with all 
                          construction software programs. We constantly update our software 
                          as the industry evolves and provide you with updates as needed.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                            <svg
                              className="w-6 h-6"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="text-lg font-medium leading-6 text-gray-900">
                            Minimize errors and material loss{' '}
                          </h5>
                          <p className="mt-2 text-base leading-6 text-gray-500">
                            Uni_Link minimizes the risks of human errors and
                            material loss and has a unique collision prevention
                            system that avoids damage to your material and
                            machines.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="relative mt-10 -mx-4 lg:mt-0">
                  <svg
                    className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                  >
                    <defs>
                      <pattern
                        id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                    />
                  </svg>
                  <img
                    className="relative mx-auto rounded shadow-lg"
                    width="490"
                    src="https://res.cloudinary.com/dhcx3vzmg/image/upload/v1596211049/uujywl5yxaelp2awamd7.jpg"
                    alt=""
                  />
                </div>
              </div>

              <svg
                className="absolute hidden transform translate-x-1/2 translate-y-12 lg:block right-full"
                width="404"
                height="784"
                fill="none"
                viewBox="0 0 404 784"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-green-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="784"
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>

              <div className="relative mt-12 sm:mt-16 lg:mt-24">
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="lg:col-start-2">
                    <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                      Quality service
                    </h4>

                    <ul className="mt-10">
                      <li>
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                              <svg
                                className="w-6 h-6"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                              </svg>
                            </div>
                          </div>
                          <div className="ml-4">
                            <h5 className="text-lg font-medium leading-6 text-gray-900">
                              Up and running in a few days
                            </h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                              Our engineer can configure and install Uni_Link in
                              just a few business days.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="mt-10">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                              <svg
                                className="w-6 h-6"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path>
                              </svg>
                            </div>
                          </div>
                          <div className="ml-4">
                            <h5 className="text-lg font-medium leading-6 text-gray-900">
                              Satisfaction guarantee
                            </h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                              Uni_Link is your partner where reliability is a
                              high value. Our team of engineers is ready to
                              assist you in the best possible way. We guarantee 
                              that your macros will work and when purchasing new 
                              machines, construction software or profiles, we alter
                              the software configuration so that your production
                              remains working seamlessly.
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="relative mt-10 -mx-4 lg:mt-0 lg:col-start-1">
                    <svg
                      className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
                      width="784"
                      height="404"
                      fill="none"
                      viewBox="0 0 784 404"
                    >
                      <defs>
                        <pattern
                          id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="4"
                            height="4"
                            className="text-gray-200"
                            fill="currentColor"
                          />
                        </pattern>
                      </defs>
                      <rect
                        width="784"
                        height="404"
                        fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                      />
                    </svg>
                    <img
                      className="relative mx-auto rounded shadow-lg"
                      width="490"
                      src="https://res.cloudinary.com/dhcx3vzmg/image/upload/v1596211131/jz9cmrl4mlxlrcygvwyz.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="how-it-works">
          <div className="bg-green-500">
            <div className="pt-12 sm:pt-16 lg:pt-24">
              <div className="max-w-screen-xl px-4 mx-auto text-center sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto lg:max-w-none">
                  <h2 className="text-lg font-semibold leading-6 tracking-wider text-green-100 uppercase">
                    How It Works
                  </h2>
                  <p className="mt-2 text-3xl font-extrabold leading-9 text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                    As easy as 1 - 2 - 3
                  </p>
                  <p className="max-w-2xl mx-auto mt-2 text-xl leading-7 text-green-100">
                    Uni_Link makes manufacturing designs from any construction
                    software on any machine as simple as printing a document
                    from a PC
                  </p>
                </div>
              </div>
            </div>
            <div className="pb-12 mt-8 bg-orange-100 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
              <div className="relative">
                <div className="absolute inset-0 bg-green-500 h-1/2"></div>
                <div className="relative z-10 max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
                  <div className="grid max-w-md grid-cols-1 gap-3 mx-auto lg:max-w-7xl lg:grid-cols-3 lg:gap-5">
                    <div className="overflow-hidden bg-white rounded-lg shadow-lg">
                      <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                        <h3
                          className="inline-flex px-6 py-3 text-xl font-semibold leading-5 tracking-wide text-orange-600 uppercase bg-orange-100 rounded-full"
                          id="tier-standard"
                        >
                          Step 1
                        </h3>

                        <div className="mt-5 text-lg leading-7 text-gray-500">
                          <h3 className="font-bold text-gray-700">
                            Uni_Link 1-Click Design Import
                          </h3>
                          Import your design from any construction design
                          software. View all profile operations in 3D before
                          sending them to to the machine
                        </div>
                        <h3
                          className="inline-flex px-4 py-1 mt-5 text-xl font-semibold leading-5 tracking-wide text-green-600 uppercase bg-green-100 rounded-full"
                          id="tier-standard"
                        >
                          or
                        </h3>
                        <div className="mt-5 text-lg leading-7 text-gray-500">
                          <h3 className="font-bold text-gray-700">
                            Use Uni_Link Product Builder
                          </h3>
                          Our innovative and comprehensive design software
                          allows you to drag and drop patterns in 3D and design
                          your operations yourself.
                        </div>
                      </div>
                    </div>
                    <div className="overflow-hidden rounded-lg shadow-lg">
                      <div className="h-full px-6 py-8 bg-white sm:p-10 sm:pb-6">
                        <div>
                          <h3
                            className="inline-flex px-6 py-3 text-xl font-semibold leading-5 tracking-wide text-orange-600 uppercase bg-orange-100 rounded-full"
                            id="tier-standard"
                          >
                            Step 2
                          </h3>
                        </div>
                        <div className="mt-5 text-lg leading-7 text-gray-500">
                          <h3 className="font-bold text-gray-700">
                            Program The Machine Batch
                          </h3>
                          Import the list of aluminium/steel or PVC profiles.
                          Choose the operations defined in Step 1.
                        </div>
                        <p className="mt-4 mt-5 text-lg leading-7 text-gray-500">
                          Uni_Link optimizes the use of available material and
                          the algorithms detect collisions before sending the
                          information to the machine. This can save up to{' '}
                          <strong>30%</strong> in material cost and prevent
                          damage to machine tooling or parts.
                        </p>
                      </div>
                    </div>

                    <div className="overflow-hidden rounded-lg shadow-lg">
                      <div className="h-full px-6 py-8 bg-white sm:p-10 sm:pb-6">
                        <div>
                          <h3
                            className="inline-flex px-6 py-3 text-xl font-semibold leading-5 tracking-wide text-orange-600 uppercase bg-orange-100 rounded-full"
                            id="tier-standard"
                          >
                            Step 3
                          </h3>
                        </div>

                        <div className="mt-5 text-lg leading-7 text-gray-500">
                          <h3 className="font-bold text-gray-700">
                            Let Uni_Link do the work
                          </h3>
                          Uni_Link transfers the optimized lists to the machine
                          - completely automating the machining process.
                        </div>
                        <p className="mt-4 mt-5 text-lg leading-7 text-gray-500">
                          Each part gets a unique barcode. A simple scan on your CNC
                          machine, automatically programs the machine to the
                          specific machining on your part.
                        </p>

                        <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                          <a
                            href="/#contact"
                            className="w-full px-6 py-3 mt-3 text-base font-medium leading-6 text-orange-100 transition duration-150 ease-in-out bg-orange-500 border border-transparent rounded-md shadow-sm hover:bg-orange-400 focus:outline-none focus:shadow-outline active:bg-orange-600 sm:mt-0 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                          >
                            Book Free Demo
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="used-by">
          <div className="bg-white">
            <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
              <p className="text-base font-semibold leading-6 tracking-wider text-center text-gray-600 uppercase">
                Trusted by over 5 very average small businesses
              </p>
              <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
                <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
                  <img
                    className="max-h-12"
                    src="https://tailwindui.com/img/logos/transistor-logo.svg"
                    alt="Workcation"
                  />
                </div>
                <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
                  <img
                    className="max-h-12"
                    src="https://tailwindui.com/img/logos/mirage-logo.svg"
                    alt="Mirage"
                  />
                </div>
                <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
                  <img
                    className="max-h-12"
                    src="https://tailwindui.com/img/logos/tuple-logo.svg"
                    alt="Tuple"
                  />
                </div>
                <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
                  <img
                    className="max-h-12"
                    src="https://tailwindui.com/img/logos/laravel-logo.svg"
                    alt="Laravel"
                  />
                </div>
                <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
                  <img
                    className="max-h-12"
                    src="https://tailwindui.com/img/logos/statickit-logo.svg"
                    alt="StaticKit"
                  />
                </div>
                <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
                  <img
                    className="max-h-12"
                    src="https://tailwindui.com/img/logos/statamic-logo.svg"
                    alt="Statamic"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section id="contact" className="cta">
          <div className="relative bg-white">
            <div className="lg:absolute lg:inset-0">
              <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <img
                  className="object-cover w-full h-56 lg:absolute lg:h-full"
                  src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
                  alt=""
                />
              </div>
            </div>
            <div className="relative px-4 pt-12 pb-16 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
              <div className="lg:pr-8">
                <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                  <h2 className="text-3xl font-extrabold leading-9 tracking-tight sm:text-4xl sm:leading-10">
                    Let&apos;s work together
                  </h2>
                  <p className="mt-4 text-lg leading-7 text-gray-500 sm:mt-3">
                    We&lsquo;d love to hear from you! Send us a message using
                    our contact form or send a direct e-mail to the addresses
                    below.
                  </p>
                  <form
                    method="POST"
                    className="grid grid-cols-1 row-gap-6 mt-9 sm:grid-cols-2 sm:col-gap-8"
                    data-netlify="true"
                    name="contact-form"
                  >
                    <input type="hidden" name="form-name" value="contact" />

                    <div>
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        First name
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          id="first_name"
                          className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="last_name"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Last name
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          id="last_name"
                          className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Email
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          id="email"
                          type="email"
                          className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="company"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Company
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          id="company"
                          className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex justify-between">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Phone
                        </label>
                        <span className="text-sm leading-5 text-gray-500">
                          Optional
                        </span>
                      </div>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          id="phone"
                          className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex justify-between">
                        <label
                          htmlFor="how_can_we_help"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          How can we help you?
                        </label>
                        <span className="text-sm leading-5 text-gray-500">
                          Max. 500 characters
                        </span>
                      </div>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <textarea
                          id="how_can_we_help"
                          rows="4"
                          className="block w-full transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5"
                        ></textarea>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="how_did_you_hear_about_us"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        How did you hear about us?
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          id="how_did_you_hear_about_us"
                          className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="text-right sm:col-span-2">
                      <span className="inline-flex rounded-md shadow-sm">
                        <button
                          type="submit"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700"
                        >
                          Submit
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact">
          <div className="bg-white">
            <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h2 className="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
                    Sales Support
                  </h2>
                  <div className="mt-9">
                    {/* <div className="flex">
                      <div className="flex-shrink-0">
                        <svg
                          className="w-6 h-6 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                      </div>
                      <div className="ml-3 text-base leading-6 text-gray-500">
                        <p>+1 (555) 123 4567</p>
                        <p className="mt-1">Mon-Fri 8am to 6pm PST</p>
                      </div>
                    </div> */}
                    <div className="flex mt-6">
                      <div className="flex-shrink-0">
                        <svg
                          className="w-6 h-6 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                      </div>
                      <div className="ml-3 text-base leading-6 text-gray-500">
                        <p>info@unilinkautomation.com</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 md:mt-0">
                  <h2 className="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
                    Technical Support
                  </h2>
                  <div className="mt-9">
                    {/* <div className="flex">
                      <div className="flex-shrink-0">
                        <svg
                          className="w-6 h-6 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                      </div>
                      <div className="ml-3 text-base leading-6 text-gray-500">
                        <p>+1 (555) 123 4567</p>
                        <p className="mt-1">Mon-Fri 8am to 6pm PST</p>
                      </div>
                    </div> */}
                    <div className="flex mt-6">
                      <div className="flex-shrink-0">
                        <svg
                          className="w-6 h-6 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                      </div>
                      <div className="ml-3 text-base leading-6 text-gray-500">
                        <p>support@unilinkautomation.com</p>
                      </div>
                    </div>
                    <div className="flex mt-4">
                      <div className="flex-shrink-0">
                        <svg
                          className="w-6 h-6 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                          />
                        </svg>
                      </div>
                      <div className="ml-3 text-base leading-6 text-gray-500">
                        <a
                          href="http://www.unilink.be/support/UL_TeamViewerQS.exe"
                          target="_BLANK"
                          rel="noopener noreferrer"
                        >
                          Download Uni_Link Quick Support
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {!collapsed ? <ModalVideo setCollapsed={setCollapsed} /> : null}
    </Layout>
  )
}

export default IndexPage
